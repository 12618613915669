import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Proof from "./proof"


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 50%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 600px;
  visibility: hidden;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  // border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 50px;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;


function App() {


  //merkle root 2


  const { MerkleTree } = require('merkletreejs');
  const keccak256 = require('keccak256');
  const { ethers } = require("ethers");

  const addresses = ["0x843a46d1405f22903dd1cd1ad80863dca236bacf",
    "0xe23cae36064f624d4c549ba812ab9c9cfdfb8345",
    "0x309d458aab130543919b9d1a86f19aeab9b6d331",
    "0xc00604a96e6fa9f978e977124ad00638fea52d0d",
    "0x58760bc194b89ed4efb7e10eafd2e17bfc4c8b8c",
    "0x2663d0b5ce30d11198ff338ad3cd2d03ef12e51f",
    "0xa5e835a64a048a33da641a842bd11ce8ac332c68",
    "0x2e0fe9dec76f1830e8daad7a2b7156b447cbb2c3",
    "0x2ac6f6702f5d685a69258283db04b8bf8494f58c",
    "0x2ac6f6702f5d685a69258283db04b8bf8494f58c",
    "0xcf9b5cb8aec81d8aeab6cf4e62de238e6a7f82d7",
    "0x9cea5304597dd796ad56b2b21a2cb8faa346afae",
    "0xe8e51870d732d4238d3e30825e9b5921dabcebc0",
    "0x48913931dbc880d72fabbd550def2c701f549956",
    "0x8fc7d1a9cfe25e9a0425bcadc19024edb8d2d849",
    "0xc68304e439e04b0ea0c0c07a021a26bf708f7669",
    "0xe803dcc2e2694a6b7f7bf426200e47de07bdc418",
    "0x52a0877f3af000e91cbf213cfea8c801e0e4b579",
    "0x0da9a09cd083602b0e31b941e374d660c966670f",
    "0x20d75f0973532f9f9f5295f4d97f00f719335884",
    "0x8219277a3ea5c1c2af71377b1c91aa7e4258910b",
    "0x489d20b135c7e7325f22de88feab1b4b0bd61a8f",
    "0x5489a663225fee3aa5bb8426f388bafa3ff3482c",
    "0x5e835798876124f5bdea5682a37f15100ee58903",
    "0xae150fe9af090eceded52dbadeda6236f3acb865",
    "0xa882fb71aa1967a6a8aa11375f8abd5fee5bdd6a",
    "0x2cf1e7137efae14caa26b9bd60cf16fd52d5157e",
    "0x20b3a40d948f7f8efe0eef35876c63a95984bcde",
    "0x0c366cdeb15384a95f03866eebb9f2882f1e4288",
    "0x5b81ea36ee1cd27847a52bc4e39e87b13f3b1eab",
    "0xd044652a8c95d46d9494e34bc018fa4d1786f5a2",
    "0xf6803179f07aec32ac85c56420554632aafff830",
    "0x597d79c140590c64a29191f5beb40a3b24130fda",
    "0xde12a1587703c494df896a69ff9e2ae674f371a8",
    "0x02b239b5057af67d67cb15145dabd77f109b9b7a",
    "0x4ef648ad96db020111a3a0e7bce4ff6e1af3da68",
    "0xe073d561be2f874eddb1832a9bf269e2320dc2a2",
    "0xc9ee5b6870d74f3fd1ad903e7716bf576a806cb9",
    "0xe9fe9724b4da373128187fbcb152341aed041be7",
    "0xc456e918889145eeaeff958b7a54168865552198",
    "0xa065641e108451b9e9d258bf29cb6f744f0aafe3",
    "0x5ef6b0fd34b6ecfce0f8eea42c869fae11a3dd53",
    "0x92318ab003a1ca62d89fe47b164353c96aa480ce",
    "0xe120eddd8a1fea7d73aad75d8ed8406988b2c98d",
    "0x222188a3122578d241c2da7aafc89ca5f15c1d43",
    "0xb3407f85880a874265c5eae427db0dcac866adc1",
    "0xf11316f7a74217e1383a3e0988829831e0329306",
    "0xa7f8a3def4ca9fd6f96fa8fd60281b46fa46bdeb",
    "0xf8f8c2ae625854eea8e739af7434eff5075761c3",
    "0xc08750d8b457d6dcb7271eaadf55c97d8711fe72",
    "0x14e2e4c71f8cfde4e1f7be6df77d570317bd0cf1",
    "0x086b5c503814a68a25f3b179f47ffd3ee08d5ac8",
    "0x6bd6775ac695e09f3fc3bcae02d009b170b3b71b",
    "0xa1e06c8f8558bde1d3f9912007763af512e760f4",
    "0x39e028c0938ad4050a62d6cbb4748b756c069459",
    "0xc5c71873a35a83af8a232e615d520ea6be82934c",
    "0x0ac5eb6375c77655e9780b7774172213940344dc",
    "0xafb6132aa01512f919970eeb91a9c64e4b283255",
    "0x06f7e095185d9f0cf43ff35824ab70f81e56e651",
    "0x61ae163a68e8abd747e82d0efa8ec6158ae4a0b9",
    "0xbb47d3d9f9381a7819c8dc156d7244c570fc363b",
    "0x875c3249922162502640ec989f2f2c47ef822d71",
    "0xed45ff9490723c2fb4a354e4b554c357604ea73c",
    "0x8da7beedad248852032d38f34e4f210ab18ef06d",
    "0x480c595b754c6cdf92b6dfdb1e96492f17f8e99d",
    "0xde5054899e767c3f3ff362f94da545642ba03f3c",
    "0x5a767f0966b35dc8df2558066beedf6af8d4f793",
    "0x81fa9463dddcea249a54fc2ff03013c5f48155e8",
    "0x776d4cb1b054b69223ee491091d6f97a46c0b301",
    "0x5dd033716ed8293638dee697c08c7dc107ac818c",
    "0xdac26dbbb2b1d86747b517d4c5e8805ff51dca35",
    "0x8acd9cc99d622fde692f0f6ebb6c840c41d7df08",
    "0x006eafb42766d8ce30b9b567733862da0021e91a",
    "0x54af86b866032e08217697c39e0b7d0f0fed8ac6",
    "0x6e4ec434353fe3a7d9d0b5e0ba4500d9945289df",
    "0x2921fceddd338d81aa7a3d143993cb89c3cfc5cc",
    "0xf88ba999020b7bae8186efb2a4912038e6cb7ad6",
    "0x2921fceddd338d81aa7a3d143993cb89c3cfc5cc",
    "0x350679cfee755a0fd5d67dee8b2d4dc21fbd7ae7",
    "0x601b6bd68a2cb72b647afdf237d6d3ee2316b256",
    "0x0bff709390e707db5584c62144d74cf775239bb7",
    "0x86d1b48dc015f8acb88b251b81885ab8cd3ff548",
    "0x7ec80ceff70d8e570d251cd5e3f3fd2b15562696",
    "0xcbd6473629e43da2282e9059cc74ee5a1c8ac34a",
    "0x2cdaaf054a63c2eaea23a7a071e39be872f2f808",
    "0x183fb62eaac849510ba038cc4beefcc6e59f33f4",
    "0x6c9486f50545ae405ea6b882bdee105a5fb78459",
    "0x61ad48a12f7d677725d1b78e1b133fc2b7a786f8",
    "0x957ae0c4ef6be6752c75d1fdd29e3e4375a3fee8",
    "0x3b9fdaa19f07dfa93204f323cd5d14c85e3a5c72",
    "0xa9dc690e6f9b57d3df6e39ac667ef6e86f520724",
    "0x8de0cfe895025761a0f022f6b66b9b4ae583b5d9",
    "0x5ee6f72384259377e6a141df184d2d63dde16c37",
    "0x82d7d9285cb50efa7f2845a5b665c3603ab3dfe0",
    "0x99940a2cbf3495c22b6b7ea64e4f977695b7010d",
    "0xecee32fecea53710c95977607121ae9243974584",
    "0x6dea622863ec4edc64feb628a2fc54a9dab6441d",
    "0xc2946f834197fbed96a7114e4a46e4500f6cd94e",
    "0xf4279032539fb955e48037bde6bbd77e3e493c83",
    "0x6d80e397d2dfb11b79e896956edc172925dd04c5",
    "0x130728d74d255f96ad0007beba24911660863cf0",
    "0xa5067b4b4a4b98508c829587d79c569283794535",
    "0x6e1cf94c71f9da360e3b70ad64f70a6ae99d021e",
    "0x4eed48827d2e70f801e0b25de6aa5e5c36fe26cd",
    "0xecde97f066dcd1aaa86fe8601b7f91ed0ab97481",
    "0x6197406521ad3ab07293549df5281e49400ef8d7",
    "0x8331d1cb4d5cf5fe346528bac8f6ff3b30c90450",
    "0xba975f357ee882da1af793574dd7bcfd619e2bdf",
    "0xdc70979196796ac042eb548b06a330e87c26b303",
    "0xdc1dbe2edf175bf1ef88879863b3bafcddeafcea",
    "0xf489a90de7ffae074b2194a04e46c65002493d19",
    "0x4a9025b24b4936fdd385d0516d3a73c6dc51e45e",
    "0x0a41a9be79569e91a75e6edc00252a9bab32a563",
    "0xddcdd2f95f1fcc192868281173857fc835d8f211",
    "0x6f476ff33e56d1b05fb761570c32ab8b9d6dcb8b",
    "0x5daddbb147e30b1a49b3a6910c4722a2dc3dfa45",
    "0x097ac1bbaf1696d4d8d6df48850d2ced7340ddbf",
    "0x5a433c4c9df44a7664442d34ed52a42d6db6479f",
    "0x5e401b277aa757e39b709c87a9d8affc5f45fbcc",
    "0x13c9ac07694cf879d2aa32307b2ee1604a409414",
    "0x4910d42a595ce640b65a06a331fd621b51fba0c7",
    "0xed97859cf6530b804703f2f8846b3e51a2f6a4a5",
    "0xb66bdcbb6d8eb958bdcfa82e496a9ddc5ac80fef",
    "0x15a2b2c65f0f273aabd1d26c92ccdf5cb24f66b5",
    "0x21197628882d449dcecbeff0e15824ad4652aa95",
    "0x4f31565ad8fab72d44916563c2b12639b62a32cb",
    "0x21bd84dd3dcd87b97dcacb8144826e4b01ac223c",
    "0xb5750a89f5b23ad981f0d07f2272b72b78a44692",
    "0xf051b3107529c85c97fde99d7feb14bca8caed91",
    "0x59f4eba08dc46c008af46dc79bffdc8d1509df6d",
    "0x63273fe6a3f19378209928185ec92b73b5b901c8",
    "0xd5e1c93e4b6d5e8be7735df7322a679424d9c8af",
    "0x7caa9f43822e288782e3e8797c8a16774c689b3d",
    "0x75e31d9da769732658b7514a4ae57ecb29de86d6",
    "0xd26f7f6b3bdacf9b34aa3884962ff0d4f151c7b0",
    "0x0b830df3d03b0190f60e330642152583cb9b0403",
    "0x1fed84485b7fcb8ffa660647ead4ef26b88abb6f",
    "0x8c08c935483e02ed5b0285ce4a7bcd92b61dae9e",
    "0x84c53eb896f8469e99e42044fedea7190cb9e11c",
    "0x638877cf3bddfd8c841ac6e7d9357428d7c9422d",
    "0x0b7b5e31a5cd3a7797c47da9b7b2ba5ff581b64a",
    "0x9070f42b359f0cb121342da92c86f78f77830fb4",
    "0x7029f1f920b84bdad362c7f96913496c30fa3fcd",
    "0x1958da56fbd6ae1d81c23abf86189b34f9178996",
    "0x350a49f718589991851009b8bc86e63646fe2c40",
    "0x4b98ea1a70df4e84c556ba1f0f41860f3dd3d7bf",
    "0xe38f404fa2bec3f1b8db1754f978e90aca194434",
    "0xa8b6a1ff91a3506e05224ba87b2dc2c315b4057f",
    "0x3f1f3e6e454a2132f976065c744f3fc5337e8ab0",
    "0x8ecbad4833ffe28125ff23c9ed80f4c4765246dc",
    "0xaf33531fecd42fd9621b62b6cb372b72f0ad22a4",
    "0xcf95cec457a16c3f2e7c3205dae53526eecab0be",
    "0x64ff8a32bd2b2746ed2a42ce46eb1bd74c59f70c",
    "0x4c1d6d724cf8603e5756d238abd661fbb02f1c8d",
    "0xa97f5523e5306c0ec3e7d5f00577ad5714d60bf4",
    "0x2408e3accd020a11a8ceb0802c8060fe9983fb15",
    "0xd060c64c2034572379d8906d948000660c8fd02f",
    "0x18ee55cc36387db47c1a9808b979ceea5401c300",
    "0xe9ebf64f54ca0a1767632bf2b84f6e697f03b105",
    "0x01bcebfb0a31c41d50e1587ac259285271f29321",
    "0x91ecc71f843a9cb656d79d91f576fc78dff2a16f",
    "0x8fc7d1a9cfe25e9a0425bcadc19024edb8d2d849",
    "0xc49a88422fd4a604b6bef2555e738fee67fd71fe",
    "0x876a1267a2870865c973714b7fa6cd3623ca10e2",
    "0xf328568507d040f2cc58c68381291c464c32d28a",
    "0x45825443b8c7c1da98b833371cc3da400b3c3460",
    "0x07a911c370d16640c14911f6129b9398efadd6bc",
    "0xbe1dd3b160022401341034bda5f806f25f5d84fe",
    "0x62d35fb79e1003fc179a92b88e278bcdc1ae4e15",
    "0x8641cfc99087659f0b84174a137d2c7f6fe861c2",
    "0xf65dfa6a53f24bdc2fdb213792e75693fe126fc7",
    "0xcc420ad9165ec6d0191ab711f6de688f2d313102",
    "0x0f4c48847447640697179bd565c224bc83dc4242",
    "0x6259e68f766cd394d7f6b51d30458ed63e95e28a",
    "0x307a6d37382cd6b692e3c870a24f106493cf3a8b",
    "0xe2039789c3392c292f194da98dfc2a75a0068e60",
    "0x80f393e9765f6ea3e66ac035e335e28eaa6f214b",
    "0xb5e56848e46cf9648bd2415f48209b6085d5d80c",
    "0x4d4310d686da369e0a9d419d0eebc11f8a565033",
    "0x6b0651f2ee86a051db73c173b7763711d9544f39",
    "0x0684518bfe8014a5a72e1695df4cb6b3f7274bb1",
    "0x6eb70b43992ab93ea27a25d092e3e15cf3002114",
    "0x584743f6c88b066844e47db8dada909b3ec5a88f",
    "0x7113051d225fcd65b91fba20c868c9621aeb1033",
    "0x1b15d06dc34ef7096d16e61a5d28c535f39b3da3",
    "0x32643155b12de54bee53d158be6bf129fba7c6ea",
    "0x692f67fcce9ac486a6b32fcaf70603d54fff89e3",
    "0xc8967ffcc4a980a4158056339b9ff85fe9d4f29d",
    "0x7b43dc14058d6be793c323be6f6ab44337633131",
    "0x0cd313ea9d53834b5f457e251fec23116ceb589d",
    "0x824cd75db050a171bbfdb8067544bb009a866b87",
    "0x0376d413d955973d564c70c4c99019e40d57670f",
    "0x262810e05b00fe2c2915450bff69821ebbae6bdf",
    "0x90dcaccc5a87f303eba23e81a517e75b1482c10c",
    "0xb340d9f239d101d8791ebe3add34675ebc184941",
    "0x509ffa992c7367812576e8d23d03b609ea67150c",
    "0x86a26f01caff39c28cf5d8df1baadc81749063eb",
    "0x712142290825edee17b82868a37871c8ef14e9df",
    "0x83d0a7ee99ca499c447cab722da02a71aaac6b15",
    "0x9283b44a6e4b5c12ad3ed2a56dff38d4496e2506",
    "0xa9e73211a3e854bb76c12bbd7ea02caa8e447a20",
    "0x467bcc52290ed722c91bfe4fdc878fc3ba8657ba",
    "0xdb3f33c806b55080a4201e67df513bbb9f80e918",
    "0x0b677fde9db78543869ac728e11738e8caed4d36",
    "0x144c02f5370be541e123fa5cf9083e30ab7c5a04",
    "0x8c8c4f998013b764499c7f5462f4b5c9e0f85faf",
    "0x952f3c482d3a7ff3b6defc6b40db7b9a0580a0b7",
    "0xf3d7920fcc2dbf9aa219f6a751028c06cf61133f",
    "0xd3f9aaf681b5a7ec3a513fc5a813c136f581c365",
    "0x08a7bcc503c5a2bf49f320f7c298c958dbd09fa1",
    "0x56a2fa90fd15c3f842b8343d01926c1fa6ac2eac",
    "0xb583527c20958b196dcb533b0ee64eda207c9555",
    "0x733155767af75d5599a9853c9c73bddb5cff140b",
    "0x90df22cf040e779c8987ad03bd42b66742830b19",
    "0x1eb4e259eac3d97ced2d88923eb3cca5139019f7",
    "0xe1e9a5b0e05267f5ec4305efd3389ebe2b93d954",
    "0xa6947993e0cc7b21a0b9d8e9379347c778340875",
    "0x478e15ea7180fb881123d5e16d27710cb35e0cbf",
    "0x2a3057841c9319aa6221d6316dd41d67682b5cd1",
    "0xd5a7dc388a1df96197e77d3f48ce881417b40997",
    "0xdb8b22c4382c7aee0261dfc16f3cdad9c4349f1f",
    "0xc90e94d5dabf093045a642b21b6359bbd2863681",
    "0xa64f77da98a87704af34f977f2b228f77a13bfc4",
    "0xf32e1cfc6f012f9104c50ca3f6fa552f5641f798",
    "0x7fe9e5281ea10d216f503b3f0deb604015ac23f2",
    "0x5e1e3c0591c6e104b2bba63a62bc4a7fc25f4f7e",
    "0xeeabbcd03e04e2bf8fc48d499a11a260de5052b9",
    "0x08a015b7e8003b2fb87d5851934138ae1766cdd9",
    "0x51a15adb74aaea38d66f1d6446ea7a43b71eff89",
    "0x16979a9cd7ffe3b8f3d5bb6d11686c3f87df05b7",
    "0x48465225bac5736ccea3e00fa18cc3b7b02cd70e",
    "0xf8da926b6f798a3b264328c35dd68023de78c859",
    "0xbedec7b1d3f0cf8a8621168873782505ac32e960",
    "0xf83b248f203e7c4039e075dd0aecd91f8f45d7f8",
    "0x2aac00a9cf4d4f1c304d0f904a7b535e84c08037",
    "0xeb0311c5d6e7157f254bbcbae44c408429b8c472",
    "0x12c7c07ea52f97911262cb219167d7f0192c834a",
    "0x4ff0c0c746e3b2b60b1c612909143e8a8692a74a",
    "0x261495d4994f40705b842377ffb6589cada10fce",
    "0x997a4da805950bddb557e51766462b9922ad7c2e",
    "0x21efc3dd7d7c85fbe2bab255d5b405f5706a277d",
    "0xcb7448fd4dc945487bfab60d67198099542f9def",
    "0xbef2f288b78d45607240826c0d07266228aea280",
    "0x0b858752eb66c0386f86a12b6db42ca4dc666924",
    "0xcab6c01601302051c8a10aa05fe97ff2e895bbb3",
    "0x63dd39aec53c7e50c023868f46bd1391e54988ba",
    "0x94757206332ef51aa7182cc9e1199966e63637fc",
    "0xdf93c9a9fee6656c9ba2dd01c175f6d23c95fc13",
    "0xf9055fbe74e13e6141bcf7143c42a57ad68e778b",
    "0x21431f1065663ce883f5157eddb6f2fd9df66451",
    "0xeff9895f8e343079fac875ce8c33b3995f7febb4",
    "0x4181482954a709b4295e16c8b1802ce9d0a42637",
    "0x0020a625c3d14b98cf6dca7529ecd6330bb09d72",
    "0x1c57ddccb31fa0cf7b7ffdb12cbebf80721b91f9",
    "0x7b8bd0fdfe8a7d9188c2cac10026342e0e669ea4",
    "0x852264acbea3ae2643768f0797430023b09b0b1c",
    "0x428155adde9887459da60a2fdf9f3aac9043f9b5",
    "0x1ec73f1090295dd310abb8cd2b83c8f7622e3917",
    "0x9caba76e37c3762908eeb90281dfe189fc41c4e0",
    "0xc7820258e2a412aac1c76aaf8fb384c239eb3c28",
    "0x02af26ba503689762fc252360bdea80de423b169",
    "0x21f74bb7dde14b2d5da36df2d57aaa891e3a0039",
    "0xd23392dae1d5f2d447dbdac536c38691f95cfc0e",
    "0xbf2f6bc4fda1c7dd8c1824e8c8984034ef3ec84e",
    "0x02af26ba503689762fc252360bdea80de423b169",
    "0x81ed3c73e67e6eaa9842f1e4a1cf77974db8da77",
    "0x939ee9d9ec03fa4abdd8cc44d138b4fc6dc96d76",
    "0xdfdf3281bcc807662699f94025b487f0e934028c",
    "0xb8695c162918b1199c3ac0c99795432c0041418d",
    "0x7e2aeea84a4b351e915d1c8ffa2b60dae7ef6a86",
    "0xc22ed8a691632c0312399200da94f007c40846ec",
    "0xc7d5d716a8b42821dddd5b3f278a36d0be137b2b",
    "0x1a91b8a792c325d88666fe27a0b8358d05a81b2e",
    "0x818e931d97187587cd106d41809037d53893940b",
    "0x18edb6649b942c09f1f7a00d1f386f6829a9964d",
    "0x86acf41e340fde55630bfcd879d5a166f114cf75",
    "0x97c87d4352a6058232ee94dd0258def30d6959b7",
    "0xf282745df2a1797e61d2232e45d9068d91f8b9a9",
    "0x5000fb98fa3db339634f98e7c883047e30414e57",
    "0xff3a72ff37b874928d294361f90bb534ea7eca65",
    "0x2883881500fca359758f3635a764cd5d768818af",
    "0xd6c2e60cd5a237b8bde421ddea715b8b9907efd4",
    "0x2eeed39008b34aafba65a0c04189728a989655af",
    "0xd32cd3a80eb639c86187b66d20a9c2dd22c251c1",
    "0xcbbb31b76ed2c48d824bae72e7f1ab6104f684e8",
    "0xa309f1ed0081b0e3adecc3c3d92114b4b7b81e94",
    "0x5df4e62206c187f4623e64b8e603c736693f4cc9",
    "0x1bbf83b54cfb3625d84bd251501ac28dba8a3373",
    "0x24877757fd4c9a029e702f12af7dfe3fbd57820e",
    "0xcd71a3972f7a21dd4ef9febac48bf96ce727e066",
    "0x0d86df45e63d3ac37b32b66b6cf8a8e9b021304f",
    "0xc0bec0e407df1dfcd5fb0db56490a043c9e35505",
    "0x7f4a46ae3ca6d92fba16e01c8cdde8a8bb2db43b",
    "0xda1665e26217c97f1759ca084277276f96b47183",
    "0x4557f0ceac4e6e5f0809cefc529853f064898719",
    "0x4931972892a9dad09d4fbc649a87a0a7fc99ccbf",
    "0x89f9c08e672c3acba7bd1390010029a2010edc5d",
    "0x84df92a0a1233394bd311b51a2a6130750893bb5",
    "0x06837ee01747ca90811e63f09e51127f0393f0ec",
    "0x156f739c760d3844f7f538341b6def7b086ad8a0",
    "0x91fe9ded2794d7d038d886d810f29e2bd086e690",
    "0x1f34f0436def95b97a3ce29d9dda8b89a3237826",
    "0x1edf61d2531fe6a352851dcd2fc5c8d38ec8b72c",
    "0xbc1f4e815de7f90d37d19d216ccd95b8c8db34db",
    "0x35129ba22af9ec64e54cb7ce0826af231880be8d",
    "0xb4ae11b7816112f8684a5d464d628fc33ebe1a67",
    "0x798cee9e6a3d03ce55fb1101fd4bda7fbd35f06c",
    "0xc2125c2689dcabbcb6afb2cfa84f46e762cb464b",
    "0xa8b896601ac4dbfde6b29e5c10faa7beea1317b0",
    "0xed4d6eb4ffe46e1bf4fbea8cec5691cc521a6cbf",
    "0x54bcf4b079fce4d95677c92a94eaa76cfcebc15b",
    "0xb442f87d38acc9de19058ec2eec6532c450d48d6",
    "0x98cb129fbb5f792c9435e31368a2d66b99ca26c1",
    "0x74e53743d2fbdd0bd6cc012f011218bfbb848701",
    "0xa7f8a3def4ca9fd6f96fa8fd60281b46fa46bdeb",
    "0xe322d1af37949e860dfc6b2fe740d550b695ad34",
    "0x06a160150149a3300948a671b2f99b8449ab6ad1",
    "0x8b82aab492f9bf28456c91e4bd28633fb4c5b0bc",
    "0x881598858daa7043ddb063d22db2a8bef67a4321",
    "0x7196cf952d331d673e22e2ea782c262d8cf0a523",
    "0x6a061784fbd635c80d1be51d336c8d72be31e560",
    "0x2f93f32258821cde7e79143a63a1195f53d68992",
    "0xb4413adc52ce6c18c6e5ab54ee83a4366eb875cb",
    "0x26a1143fb49978823ba46d7603276476af9ce2ee",
    "0xa84b405a15ed2a5784d9e5be8f2b977dbb9da65f",
    "0x9c9217783e69c7da753afa988198308412241c8e",
    "0x409ae77851ea1cb778257a3a821f97e6ae3c8f4b",
    "0xd44f47113bfd46b47a5f387b2e7b23cad17cc9a5",
    "0x3fdd895961ff1a00c5cb8773101ee7938b0192c2",
    "0xfd383ccb6484f26a264a389f656559b0f12d1dce",
    "0x6547e469765712c69728d603420f6b574ed05f17",
    "0x10f685dc1a0efb3f89c17abe55ab115341816d72",
    "0x51992168a27bd03521cd47668c7f71edaa7fd753",
    "0x73d28b8da7bca081a7d2a54bd871a33ef607e4bc",
    "0x3adda9019d09ab8816eb7cf389642e913e549053",
    "0xa6869ccf639d702b830dfd30ce74511521ae3bd9",
    "0x9525ba68b0d32449641f2a5868ccab02f77a4537",
    "0x22aaa4b90ca8fe7b8b65fb12cd7b7f0fa1a9738c",
    "0x001a181ab8c41045e26dd2245ffcc12818ea742f",
    "0xc18bc1b05819150931325a2d4f47efa8abe9717f",
    "0xc1dee7c83495fa42fa748940931a5eef751f91e1",
    "0x62e7adaa619ce749e1e0bd4b31a71627978a36e2",
    "0x28944257e11dbbba3e0b9e0fde7a9b4fbf8e572b",
    "0xda8447269e3d28482f098c848b80add39f09b1f8",
    "0xe2eb837bfab37b2b6f95833d881b00cc66bf9df0",
    "0x325d841c63d7f683c1401de38a07ba2d1ee5c4f9",
    "0xbed2ecb6b6b38d2d4bbe1bc94593bc420647fd82",
    "0x06a160150149a3300948a671b2f99b8449ab6ad1",
    "0xda165a8632ede3fa656c6590c2fe26cc3f634df5",
    "0x759070e9eae3cd447eaef6b174d17688db7dbce7",
    "0xcff5b5152e34804a8faa8d763e923c82f4232a1d",
    "0x352c2b2e5b85e1241b4e38d4816e274ae5220d59",
    "0xcab18151ff4ae8e8c56294dfeddc84fe72ead074",
    "0xe3e410250befacb8565d9691b02690402f074b72",
    "0xea7acd58e94a3b79c4455c7483580a9655a71fce",
    "0xe08c6a7eab5e570f2fe3e41f20138ee1ab5d87b6",
    "0x65992556cc43ddd85735cbe4376b08218ec2f804",
    "0xfcf4a28b616fab313ad4e516e21359fb5a1f5a5d",
    "0xe451f67fa26b860333d5866c7cce3d73570bf6d3",
    "0x259e4bd1f1f803db79ee71ab19ad49e43c0e1b0b",
    "0x3c4e81fe0504cbca8177752df47fa42444277851",
    "0x1d58c56d6fddf829d940a7ed6211e145de3c1c34",
    "0x2190d4ee2bfc8883c3d71f4b5f41acd7a7287ff5",
    "0x7bdda017b719bb48078b4bd7b0604748184ef807",
    "0xf12c90c7939709a727da4966e0072449813a176e",
    "0x48c5631a2172b8ac58bd5fc98fba3d321d1006ef",
    "0xe1f662ec2b60c6c6f3f1b7548a76cf576fe4c603",
    "0xffca6084e8b48e2704c3d8ee08edbb316653f0e2",
    "0xdaeddbb6fe764a08aac9cbf544c0a4dae5d1da61",
    "0xd44f47113bfd46b47a5f387b2e7b23cad17cc9a5",
    "0xd4d82161699d64029c4e7f7eb41494b89226902b",
    "0x24f854c69a7f654dd8769ac215f6f27c65e71fbc",
    "0xf9acd4ee1a6cffa8e9bb4089d3f9f3f58ba637ac",
    "0x0e7a316529c1d25a25f703bfe64cc4dd800b1288",
    "0xd4cd901acc1df52cafba9455de7ab687f0000000",
    "0x5e46ad0bc4f1c239c88059ba8f7a5ad9f3e3207e",
    "0x65749ca2cd37542dcaeb99b631c2e6122c1e0c5e",
    "0x859a9926a3a4aef3e4ec539bab1589e9b0296cae",
    "0x477e3af52182e3a9ebf7b1f0f31896181fdb8341",
    "0x1697be431bf2d57cb5c28512a020ee597b4f06c4",
    "0xa29da80cb15ef8ab36cec0e5cd04b43414eec4c1",
    "0x931ebf08c88eb346604786a54a10168ab75b4b23",
    "0xd7161737a58d7b728b46d7b8b88e7497cffdf3cb",
    "0x4181482954a709b4295e16c8b1802ce9d0a42637",
    "0x18f5acbe34bb8a72bacbe24a1571f42ebc413ad4",
    "0xd7a29c5b40077a8ef82681ee2c9124a9575554d4",
    "0x846bca2ef1f92021b4092e1cd0cc5c00ae2b5257",
    "0xbfa22b6cb246c657a4c5e5c5873cec672bfc4506",
    "0xca434cf098aeaecdbca8a813177a3f9d2a901b7c",
    "0x46bcc00ad361d702bbb2be07172fc385695f6837",
    "0x6c83edb9b22c9cfee1fda464fa7324e38129d69c",
    "0xb02d7591f34026cefc8ccd65c9358147a6820501",
    "0x93e572613f815d7fcaba4b25f2e79cdba3c5b581",
    "0x120d8a0359a323935756c9fc8f83fb3953d60902",
    "0xc844a4a63bb232b8c1c761663bd5e98f4068b43f",
    "0x2bc2f5b24210643464b9b9ccb679c25eda24f0e6",
    "0x15865b9a6c3f3f0369ab01e045e15726e550c631",
    "0x8dbb9b19aab9ea00facab2bc3d065850fc99c20a",
    "0xdb912fab7ab5fd40f17fd470573b3b999c62232c",
    "0xc179d9017fa3a85926442e14cf053478a7d782b6",
    "0x7cb9dc098304e37e5a778227e39c4a1e32dfe9f5",
    "0x2ad5e90f9b2ebcdedbec904d158e1d4b95ebad77",
    "0x6184d7075828f4b22e5d860aa38280ffa831ad3a",
    "0xb08609fac763e61ce18e4055270ae69e97828ef0",
    "0x66096e2dae9d79df90eadd26b6e1e4b58f68b2ee",
    "0x94131939909e388f2e16efdcefb6db8d019b128d",
    "0xa15207145c4d7b338f4887808ae56c997e415388",
    "0x21974beac80bc83b5bac2fca6a8d23b02017a4ab",
    "0x4d708b88eaf8c0eaf02f5f36c905e3ebee2311f4",
    "0xa041e8fcfeb517d9682807b5c7822655e6d3da20",
    "0x0793e40631381be6736cdae29400382fff23dec4",
    "0x9d21604785896cb4bc1ac13dace2dda20b28e08d",
    "0xa8b44d98f6092529deb51fff42a69ebd00122409",
    "0x79ed59e2a69d58de222adcb1659df11468e18851",
    "0x1b3a89200b4568111c851820b50785ec2e9e7080",
    "0x31d2614671c555d9b2d4be3093851103cb306f78",
    "0x7bac9c3bce82a34281e215a75eb7d7418c4a5921",
    "0xfede88e84f127035e76412574e1c65c7193cb8dd",
    "0x3302988b70505e2add0a4d685ca2e661da5530f5",
    "0xf25f373f4d48caa7500abcad162f234a5fc31313",
    "0xf07314442073188615ff8df0b80cf1df9e51d5d7",
    "0x7da657b1080663cf711e65716da2e754ade4b77b",
    "0xb2a9f4109d4f56d37832b56601906e2ec46ac86d",
    "0x0bda5ffa68972ae3586102def7f97083b6ab54a3",
    "0x2d324fea304db89c946ca84056a010fd4fec37a1",
    "0xbacf53f44cbe63745ffd1071edd541697f76f256",
    "0xb25b7a78e894d58b7daeee0a1f1d8d1b4a10df61",
    "0xe373e1603e6d676f32a28a030d032221c91c8e1e",
    "0x782fbcf60e1a780fa55c8ce918b4ca6349922348",
    "0x7c0f02d8e8debcc8678f2b57fa6d7bcbb5ca86da",
    "0xe40f00b3f3c56a6e2f2cd7b92831f31be60a3fd2",
    "0xeb55370405f4de3071c5ee47cbe37ec66f3fe5b5",
    "0x644bf3ac322eca793cc5aee11e6349ef6dd36acf",
    "0x7c3c494fb2e91ca98753b5b7efb22e59244b89b2",
    "0x9e1cc0bd3109526a3253f1fb53300644edb40ce9",
    "0xfa9cef2385afc33c6e8d6d9586a32d755114c12f",
    "0x5b408c0adc4c8b0106b643b4ecdfe127ff949469",
    "0x815ef015275f70143027b9857c568dc2f325841d",
    "0x52c858ff6ccfc33ec38bf5ad9a407fc9f9e3218a",
    "0xcd43cc81f00155545a98e8533369edb07c2915cd",
    "0xf53cf50af1f8e3a55601d5a3a98f3c90152882cb",
    "0x155026cf3d32957a7fc4bf6e0e076659a7e4529f",
    "0x8abdaafcd383fcb0556dd324c00b6f7e1f9bca2c",
    "0x3577e232c84119a918477efc21490acca2cb34a1",
    "0x57d9913ac750d378c62614e1d801e2ebdf76f67a",
    "0x20c7ea14ccd538a42522ab42ad9ae3ef09e69339",
    "0xa0ba557a6e953c588e747eb25380818cd2ce1978",
    "0x57971e13e42594bdcb2cad2460af84e25f40217c",
    "0x6eef5898826f1925f06e633743b23bf0683db3f6",
    "0x05dffc9a8703501442fc1b0a25976523edbde094",
    "0xb72195dd24e70460b408c16e3f3f512625504a12",
    "0xbf985988de91030b1b84d4fd61d91f1b3f6974b1",
    "0x620ef02986117d20c1e1927f878b9fa6306ac7c8",
    "0x01989127cf276c52e581f2694b4474d1dbbd1cdf",
    "0x7e7db50313f0afa280ca67f0aba74fb6b7542a84",
    "0x651470ba7a99fe391e259a4323de8ff8c609dc07",
    "0x19e50b96a45d6f1dfcdaef0a6f3d46c85a7171aa",
    "0x4d84a16025f2ebe17ce5c03691b11cebf4df37d5",
    "0x45ee74329591acf12be3f5ad06c1c99eae84410b",
    "0x2e370bf6e49a1eec4f02bc2a75890c4297e84996",
    "0xf9b0b039e114ff34076fbb4aafe6b94e81f8f1a8",
    "0xb6bc3f1bc4314d97e6d87ebe14882a703186afb5",
    "0x24a7c7d06357c790fbc13b8c95fd0dda582566b1",
    "0xd0fb7bc9e974e8e9d1d26d4c31ebe133ccf0c8a0",
    "0x18b334c31b1a681ff8763c700522371f90aa7358",
    "0x84df92a0a1233394bd311b51a2a6130750893bb5",
    "0x5b7b206203c829da4e478b32953742aff57c814d",
    "0xca5c2846d866ae6394e72057733955ac54bdedb8",
    "0x5788858ed0ad70dea5056738b7ffd1810eacb84f",
    "0x9b1d57fcc79f2f6b8247b992e68d5881a16adf2d",
    "0x4c3dd50a1ef7d82be242da23d49ccbf4a3d9aad9",
    "0x91e7df0e96b77e9a771a982173af57949cb2a457",
    "0xc3ed7ec3a69604913d8f9990ff223ac0d7281fc0",
    "0x2863ced738b626c7b32a8030ab523904360c6ea2",
    "0x35b4807475ef41255e4039a15541c03f907a3fbc",
    "0x59f96ab80871631a1248c0e77ea9057c3cc087fc",
    "0xec3eb5c74955f0ee151aae4bc6e89e8affa3eca1",
    "0x89296631778da40abaccf7f4621f26911eb6accd",
    "0x52d8ae334d7eada2ab8597585d04929b3e7b2345",
    "0x9b0fb34324a06e5bee73e6d0e39ee3e88724dd21",
    "0x8342b95d36e9b000da67672e2eb145b0490f9dae",
    "0x26e62c641a246bcb217d9ed48da8e0f411d60c8d",
    "0xb95f85c6f9502354e289684ab3bc577b6cf404e5",
    "0x846b762e809247a300d1a5014754485c0573405e",
    "0x3b1fc54b55c162dad97fc9fd7fab0e5cce7c10d5",
    "0xe85a4af39037159f17467adb5ca4e148b4c486fe",
    "0x202d94bb8a347c35db697db5857601e36246753f",
    "0x3326ff172ea26d53d539bb82ff0c1391d965ea7d",
    "0x2f7cbec4367d225dda665f501c269116f323e61d",
    "0x034d5da0a84f1653c4f64b1164708bd167f46157",
    "0x7b03ec1fa5dab48a6e9dde1a2f026d6bc1bb5614",
    "0x799ffacc4ef79039f78267a41c792a4fe2c088ce",
    "0xeb2310631c551ba066317e92988ee50e5fc880ad",
    "0x0fa84a489278c891d9938cb7e8254d7bc3681d40",
    "0x874241eb046a2d2ef9a2965faf313442783b5c24",
    "0x99577b1bdfecea6a4e5c9b3667a78dff059100d5",
    "0xfb362a82a7af0f9506b052be5632fbcf367e15dd",
    "0xda6fd6e857b669f8df0d75ea8cfe7ee5791cbbaf",
    "0x89e1a971d1f62df8ccdacfb11954d8f1c81897ed",
    "0x2025581d41f25177722ef4a65bb8cffa44d26095",
    "0x7577d3a18177530378d31120f181650e9b353447",
    "0x1e8b9679d7fb06e8a754597deff913bb436a3d8f",
    "0x2ac6f6702f5d685a69258283db04b8bf8494f58c",
    "0xf73892a76d50cff31a601e24d603e80eadfb2f25",
    "0x7e4133255a9b798a414f841ade5e90d6fc644217",
    "0xcb3bc3db85b94e80e48d74c80e9185511aee2d98",
    "0xd1e924977fd3d5e1b1965eff05dae433a6abbc5a",
    "0x54a6523f22b31a90e7b1ff179f36d609f40ff931",
    "0xddcc3527b903ee7e69b9fe397bcc790df7159c12",
    "0xfc370d158a2a7fc3ef41ee9f524e39a783343d19",
    "0xcf95cec457a16c3f2e7c3205dae53526eecab0be",
    "0x435f5120b3b6b3baa82b4dc27ae63b33322b5caa",
    "0x223a1b8d6f2ef8d83e0df91542b99601bc558e2c",
    "0xdafd85d9ed64fc71caa7df48bdca942092a590f6",
    "0x25b7514866149302622713962d98d2a17c8918b9",
    "0xd1bc87c56d5014f746112065c6d4173339ddd476",
    "0x780e9a7ffba019ae79933278b8d92c007f1852d3",
    "0x5d8360f7c22856fca78af220f6387b4a02702b81",
    "0x4f05f187cd2cd514f02ccec8d491fdb21fb1624e",
    "0x7e31c548bf548f45f048a152d121630276610aca",
    "0xf37348c0c1c564381d0b7608c0191f834a201bc6",
    "0x1ab6cff4cc001a47619bfe6ab68d90a28ec54154",
    "0xd0913874def6febd0b9aa2a93c94ae300c6c2a66",
    "0xaa876f1c66c88da0788518239fe7c92122a05d28",
    "0xeb9adf84ba7189934e63e5bceab8247faf3be2db",
    "0x00b4418aad1db755ddcae83c24b8ffee12c0aa14",
    "0xd4a8895993fffbc098c38505f3d94662fb3285b8",
    "0x02722d23859c1a83fd1028bbfcb75fad6ad0c5d6",
    "0xf5c0bb3d5f6d439900f0d6d8eab576626a87146b",
    "0x465f1b1bb1bb8879defbd9787ebf202a87f31e48",
    "0xb9b15804e6543ea01617dfcd5aa226c97434fb12",
    "0xaf420837aed23644c6a83fd41c071bd2d15b6478",
    "0xa943ab223e8c4957e2f53d2a20d0665f2c0290f5",
    "0x046c2579083f0a8fc0adf207acbb6109478d9d9b",
    "0x4f68d6a472fa0f4d122b63500696f723c0e3e495",
    "0x06f6f63d7d632d6f8f2ded80004e242e7fb39975",
    "0x96e193956178c9beb3a63af118c99cc397888112",
    "0x071ed2940fd1c5da42cb8009dcb4d4a7cee99a4c",
    "0xb4b0214bf0deae405a57d35129b64daec096c561",
    "0x09981a6d4a7764e26febea4ff53d4f505d29e66e",
    "0xfe61a3742b77a9586ecb06a62ebebd7c7cfcba84",
    "0x0cead11e732cceecc82b5f5d9180dc9d312c5c06",
    "0x2389f808b274c54e0dfc6b110e28558f2dd5075e",
    "0x0dc8f0755d81ac231e1a775fb7c72a76e04a081d",
    "0x2d7d5a9ad74f16d310ab59dc676e9978a1ceda02",
    "0x0e74fa27df3f26aadbc502f1c6c6d7cd8a27285d",
    "0x8b0dc681b1d06fd472cc5c35930d475a700a6fab",
    "0x100da4222cfbe6e8deb8cdb8c15899986cb188d9",
    "0xacf8295c5925890f1b6035a16e78727eef49379c",
    "0x124fcb7262348d38f8243754ab868095dbc01068",
    "0x1db69adbd1c9eea371b63098b6efbf5f8b17ff06",
    "0x125e13712e404b3367d24a040047295fc04eb24c",
    "0x22ec9e66935954f49fb4d75b2cb54d398a0c4b7c",
    "0x1762f43c058503ac13c18c6662021251ea4ba2a9",
    "0x6c0811f33e278e6ddb0eaeb431a993094b7617e6",
    "0x17b3281ec9fb7b01813cc727ee3954c2f76c2400",
    "0x40aa4a6e31a6420fb65b72301782c962d2349d6c",
    "0x17e7c2b65dfa261f0f8fc081cc3a5e59c7688e99",
    "0x5f3c2f785c6e180acc1ff0389c92eaab24c981b0",
    "0x197ba2860b824787379b38c9407895aecefeddbd",
    "0x56a5fb55d3e789b635b18dae15d9468d703641e9",
    "0x19f94a979b031a9585d2c794b386c44f5982a794",
    "0xbae0451f1fbde539b2140ea4ba861a295fd7f551",
    "0x1aa0e2576d2e75371a78276e9a1f6c4af4d0e0f6",
    "0x6fa804044c0504b9b3ee9c2b1408acd8063f0e63",
    "0x1af7d7010538f570eee7fa1d8e4251d20d68f1d5",
    "0xbcdf5f45655be5a8e0c1f219e2c80f1ffa9c4880",
    "0x1c8287854b0f921e206fb31fb1f7690ef86e6170",
    "0xa194557e55254e6251986982e63a0c813ed18758",
    "0x1cfc2aa94ce44fcf5804e257745eb15c61bf2c6a",
    "0x4d4c7dfbaeb0bedf04f4aa4634a317f9e93e525d",
    "0x23d0c578bf33da65501b3faf9697d4dc75471db2",
    "0xc40e6238f1ff69802def05d0f11fe3b2a698da88",
    "0x27186b00d17a85d39cfefd13a7ba3b92c861c380",
    "0x2e0fe936157bd1031bc5444cdeb2655904c1385b",
    "0x28d5b8994c3ed6748ecc059eaa0bffbb4723f094",
    "0x8a2ca64e7a3a9d008346fd7dd67db61f6eb42a51",
    "0x2a5e7c3689871a47837293823bb02744391fb7f7",
    "0x7e2aeea84a4b351e915d1c8ffa2b60dae7ef6a86",
    "0x2b3bfb70911f640acbb6f7baaf880357a02840ed",
    "0xeaf1f7a3bec90eb8daa61c157e591d1b32e39057",
    "0x2b9d9aedda825193cc7ebd512ab6e0fb485fa5e1",
    "0x79db97b222891bc4f10f16d653c1653da8207ae2",
    "0x2df01add9afd2604dc970c1b2c9e5532cc1bb2d1",
    "0x2047a92927178d5cb9d655e78cb89ccf11d1e542",
    "0x2f25413059650c5af2dc1f75c0a317cd80a924cd",
    "0xdf93c9a9fee6656c9ba2dd01c175f6d23c95fc13",
    "0x2fae61c7b7e0c073a69fe687ce977ac33565f9ad",
    "0xfae7466c2c85a43d90b9ba7f49bafd314ba6660d",
    "0x2fd501c205e86ad1e93ba8aba3c1b988bd096661",
    "0x3a63a9d90bb70ea0f76c2c2e55d7b2fe75332641",
    "0x3020136b9958642cf8e3972e06db21f3884df56a",
    "0x9b90d30ff7c8ed856d937acbf4a98f423f76b810",
    "0x3082380adb4e610565a3707bc449097284d180c6",
    "0xb5388e1effce0228eda8f7473507cf16c1f1f388",
    "0x3213d6e5a811e90272f21a697bbf41d89c80e908",
    "0x42f2746ecf7f891b62c167e16f4d1bfaa23764e3",
    "0x32bcf669c9b801d87d608990b9746fccf6c19960",
    "0x038ca3fff9270b665343d06ec1ac937eb946233f",
    "0x3333314899064c7886c2b983a4fdf2a75beacd83",
    "0x3c753055007af12cc7434f0d5ff20b9ca0d83cdd",
    "0x36077642901833812ca3f28bd82c26088a05e01f",
    "0x7499de64b726f00530ef096b2aed9b8e05891ea0",
    "0x3856e8dcf07d9c11c45de156b7d56edeb2e400f4",
    "0xc54b3a23b3e25f862f821588795846d4a498c16f",
    "0x38da13f93b0ca2b2b771f569925511176836abdc",
    "0x3b312a1496322f0c3633241d85a59ae0889b8150",
    "0x39e028c0938ad4050a62d6cbb4748b756c069459",
    "0xe5628bfb3b0a194a03af441bf29c6e6969fb0848",
    "0x3dfcf8ef31779a5d6f143533f61a31a9974dd884",
    "0xb1d58bbefbb53576e355a1f37d8c133ff0300637",
    "0x3ec6426bfec96dbd53942228bc5df729b49ad186",
    "0x7d87cd9b751e0df6f601b9d4f0ab89b606a04ad1",
    "0x4028e6efc2926b0cb5f90a7a9cfdab308e603ac6",
    "0x33a1e6f53c4bceea6db89d972fd2f8d5c11ca8f0",
    "0x4450719692d317a0501478ee3456281b863231a1",
    "0xbb43517e2fa65c21ae0ec100aef2d59d357fb340",
    "0x4558c4bfe93858c8f7a05891402155d33a03ca20",
    "0xaf5311d4b14a38d69203f985a5edb9c3943642e7",
    "0x45cf9cb922cf2a4ae088f47618fda9a8bd567360",
    "0x8fea087bc7b808897f15d9a8a979598977a22357",
    "0x47ccd79a2d04bfc8d5203b6ff340d8f24c217f42",
    "0xfaf34d9362a47c9a3902b1c73f68675015a668c1",
    "0x0f05a63ca100d4a3dc57e0ef334656fdf6340e5d",
    "0x8a03caf50b07c57f50cddcc7f000c0970cb6a878",
    "0x4bd73595b46354918266fb61c69d2fe20b5f5901",
    "0x4fa0e8318dfbb42233ecb5330661691fa802c458",
    "0x5094092e2eb3017359ba7a4c0d35824beefef2cc",
    "0xafe7c8c46b5154217d65e98c97501d551170f5ae",
    "0x5383cebe6ed6d7c89e1c3d472ee7c46794849346",
    "0x25bef8b2ca32810486d9389441893314b1b3c6f1",
    "0x546bdb76562bbd1640793d444f60e5ecb72c7e2b",
    "0xaf3d2563483e2cd8001258cbd4631b226963fa1e",
    "0x55405810e47b21bf1a719a1fc24abaca1abe881d",
    "0x0a9173154c1d2f9f840f0eebc8d42165cbbe2a6a",
    "0x57e5f404a14afb0b7c5f864c94f9e0ffdd4d872b",
    "0x0020a625c3d14b98cf6dca7529ecd6330bb09d72",
    "0x58670d157f14dcd442a212f50cd5acb82ea7fc64",
    "0xb1d58bbefbb53576e355a1f37d8c133ff0300637",
    "0x5885bb1b50305fa142398054f2c87eb55c072567",
    "0x7b63a89acb097eaf76acb8a081553e9cc730a5da",
    "0x58da309df1ad99c632e59f32be03d1868f8a4492",
    "0x982a87376575036d20e48ffd38501829863d884c",
    "0x5c6ae017a1811ae67f6aba6a07009d173ccccdb7",
    "0x36680ceaea6854f1e3eb609aff48545d4f6746e7",
    "0x5caccec0cda0dd50c56d026f9f37430595017ba3",
    "0xe976a66e36770506f0e3e0072c47d58a2cfc41f2",
    "0x5e19496caf98621e4595dc717c99b081c12ab1c4",
    "0xcf6a829f58ac1329d8e2e5b00a41d264edb6083b",
    "0x5ed6a9eed846f06855317046343311461ae89fd0",
    "0xc4238e906c3726da5bbc7e0095be02c7a228d0f9",
    "0x5f01806070a147ec832ddc92cde5f6de22d8739a",
    "0x187c939413ed6a5194822238ca3b0afd1215c92b",
    "0x60404336da180aa0ec344241ecbbad1ad2d31cb8",
    "0xc204754cb3140b413234944ae61673d571485c63",
    "0x6135b5c1307a26838fe45e4791be0802226bc4c7",
    "0xe357cdd03c1ab28151fa946d4295b2d2369c6c62",
    "0x61a4c2579b4d2c58f8ff008d10b79f35ce778269",
    "0x1c57ddccb31fa0cf7b7ffdb12cbebf80721b91f9",
    "0x638877cf3bddfd8c841ac6e7d9357428d7c9422d",
    "0x3a6953ddbc90f77fd55f26cb1980e84dc522e3f8",
    "0x6639fc8b5f028540144542f15d54c2dd8c98ffae",
    "0x74cf0e28eb50d7c667265b30edc90de79a15a234",
    "0x67f1e766bb91b3a3415cdb8554306e1a3128ccd5",
    "0x9a8aba4b10a581631861b2de9f582ba85c9ba936",
    "0x6842f35716120070cd593ac4feed21e359e2015c",
    "0xec0bec477ba282ff48ce62183780d14078ed657c",
    "0x68e42c7039f5b1caf5aef039a3957796a236f5cc",
    "0xaab7ec9e6097b79b1e86541c4b4033a8cc8fd631",
    "0x6922a2b40cafac5cb2eff0f4270e6b1c321c410c",
    "0x2dcca1a2d902d19f43450c90b5fc7b89fadc73f3",
    "0x697fea3bb87e7377cb1fb164aac837b87754370c",
    "0xd1478290eb7beb58c4afc15d8102362dca131432",
    "0x69c5319e2e6fecfb3048473c7b88f4ffe411313d",
    "0x6f1722c1e8e132477c21749d81d84abee687c424",
    "0x6a1e005554dda2a46e74585638b0df890c9e3e8a",
    "0xa7d6c91b0594e9ca55dd0e312ed6091afef646dd",
    "0x6a8f73c4835ebe3db3a5f2155093fdd4fd365cf1",
    "0xa45a3692e37089ce1afec88921650cd1f1c2c6bd",
    "0x6b7fd746fca912e8013acf662ebf7b456ddf4b9f",
    "0x6e7bb4dce7ce5fb39bb0a7b1dd5566d2d4a9ce65",
    "0x6d1b1055b024600a547491dec85b1c426dc34f23",
    "0xba2b4240ac736382b3549cffe317ef6868b5cff1",
    "0x6d2484b9c9e5961e90774ff0a6d51278bd6031a8",
    "0xdc5f1aed7223ebf676d5c18ddf343e10bfb6b0e7",
    "0x6d64048ea570589949652a120503cfe910fe5ded",
    "0xc6e1bf0ef0ee1372ae3f087ff23a9659c4d756d9",
    "0x6eeca8c6b344f8d369f19d35f776762aa7541fa0",
    "0x7f605cb5978a1399a2181e1e73fde45ab4fcf4cd",
    "0x6f84d486814791fdf0e947390db071bc7c16946c",
    "0xe9bbbfc3cadeff00b56056ab0f567efe8955a211",
    "0x701a72a37a9e017d2955c7ba0cca66a2e833423a",
    "0xac0cd67eb6bb7f16d648c17c93f3baee9f14a6a2",
    "0x70b88b5b66b260cf01aaa72bbbcd9c13a6b6c0eb",
    "0x05e7e487c8bc30595de31e761c65b2498b98e408",
    "0x71e4b02570e6ae21136bb0d49fa1e7bebc2b4098",
    "0xe1a5e58096ebd50b1f7440972cb142e3be14d775",
    "0x73b41fafc67fbee0afd35eaeaba76e7468083f07",
    "0x2057c03ee6b8789edf866ece6c72ab4303aa15e4",
    "0x76c448a2e84647139b36c2e8235bc2465dfe85e1",
    "0xd02868fccaad078545fe5c09703d176008c2920a",
    "0x787f7aac50f99b22c0e625c8d2587a023b15ef42",
    "0x9ff52d44e39be00dfa8d1afa4725482987aa650e",
    "0x799a343a127e45ba2001cdc95747bab4cbe5415e",
    "0x6e619ac069d8696077266daaeec5ab64eb009ded",
    "0x7f30c17b81a75abcb473c165162dda8b0c5b04fb",
    "0xb2a9f4109d4f56d37832b56601906e2ec46ac86d",
    "0x80c171bb7120e472d72f84094be917276a8278ac",
    "0xfd8a61c310f80fe69a6db56816f8de036d3cf2e7",
    "0x814ea2b0ed308980fabd8977be3243e73b7aad70",
    "0x71b970d9110dc32964dec2b4235267040500cfad",
    "0x830a0409e0b2e94894f273a594e99276d922d9dc",
    "0xafc0d24db87d13cb651a86dd2afd8ab3a9a13bcf",
    "0x8332ba6eb0cd67793a5c3bf4f886cd2ce4b7fd97",
    "0x363bcd8364cb5e86affbe865dcffaf58a54fd31e",
    "0x8382e915ab6a7c09ecbb88a23781c9ac3fa2c9dd",
    "0x5d50a1ab1488479f0556c94daf52e43aaa0eb892",
    "0x83f120508418e21fa41f0af0ead498c29f23275b",
    "0xc112e382f44f9aaf265c0a3be9abf36fdbd529f4",
    "0x8407dc06e1264de30c72d18c404fab186d99b895",
    "0x1fc774ed08bacf0cf1af2d9db52157140d3ea9f4",
    "0x84443cb673594043b6867744fcdde58692aabd16",
    "0x691858d4d9c0275ff6f317d46a9d655b5fd3ccbc",
    "0x8a7845a880b678447d624ea9ac67e5e67fafee4c",
    "0xa9425fd0faa2b909e67e3a6058ce52b64a8f4b95",
    "0x8b01c0ade4e7fbd281a55767527f29601934c557",
    "0xe667208550f34e09e3564c500d247008415158ad",
    "0x8b0a695b222401673c529b3b358e16f73e0a101e",
    "0x64a51ff43e4fe67e0708d7c9ab20f9dfcae0d6a6",
    "0x8b5b518ab4a67532422106cc86f1567f5543ee6e",
    "0x8a804ce4dcd4826af07ed72515466b665bd71276",
    "0x8c0d700db746c2d3ea51d30ecbbf74a379db416a",
    "0x689d570e1ef2afd9dcd842ed084fb30c9d3dfb45",
    "0x8ee9b3e39ec4125c3d6db42d7dfa1104739d8147",
    "0xf07078daa062ce456ef5f37c356551417c4e703f",
    "0x9140128c7588817af40efdb253e668743dd8da29",
    "0xcbb1662ffc44568d032500411c04253ad689d214",
    "0x93abc93b00841dad9f58ef9d0262ea2e76db26e5",
    "0xa8a453b9b8f6da431439ccc4b7b1bfb6fd944604",
    "0x93ad96ab477bccdc1c1b77ab5323f5a460087fd3",
    "0x0ee38c6615e34ee9af2ac305bdd29e259a6e9f2d",
    "0x977111160f89b83f43ad0ec11ac65f69810697e2",
    "0x1d3aa06154de5d00f5ac2619dec52939286efb31",
    "0x97c8becdb6bdb1bfc0a789cc240f0e126c9a3feb",
    "0x2ba5631c1d98b5a1090ac27b8e007054e5c8fcb5",
    "0x981101f66ebd846b37df2be7bc15e22cd40cc866",
    "0x4ab59d6cac15920b2f2909c0529995e12c509b80",
    "0x9adafad9ceacd9fdadf8b88d2aa59d3e20f5f4d9",
    "0x710af82349c0d972481aa4b80303723093a63dee",
    "0x9d21604785896cb4bc1ac13dace2dda20b28e08d",
    "0xbe4ff3623b9eb0028fee2f977a6b01a6be5f3df2",
    "0x9e084d572fb5351d3073237440626ce6fb2847c2",
    "0x67761e0a9502b1a84fddb719359b44fd4fb6201e",
    "0xa0c2ddc8b855350e5ff9514f77f6cb3d80015970",
    "0x52c21f4d4b842595500d458df97378cecc4b3299",
    "0xa0ce85810602c823d57f2252f952f6476d96a7ad",
    "0xbb4ea8c10ee916577665debcc86071d6e1bb10f4",
    "0xa16231d4da9d49968d2191328102f6731ef78fca",
    "0x1788cd9b0ab414afdf223fd725123e14515bab31",
    "0xa3d5a1e2fa1551cc706d64ae5d4dcc8145400e3f",
    "0xf37348c0c1c564381d0b7608c0191f834a201bc6",
    "0xa487b579cf197ab4f7d3a1b2b59195862da15a7b",
    "0xd0913874def6febd0b9aa2a93c94ae300c6c2a66",
    "0xa5410e871f83914f2b53f2bf7cc3c883b210c03a",
    "0x7dda8b1c5156980a06f65abec0c28849af81bfbd",
    "0xa5a6def7fd36b26b2071220197ee128782f55f1a",
    "0x1f58c835e472b95fae845656dc16919eb835b2b6",
    "0xa96e81a1c6e13be2622de4a43f510588a911d087",
    "0x79bb3f9fa746de818dfc0df9036a82933f16346d",
    "0xab6d2c999f2a2f1d82a256bad1cfe6c896776f1f",
    "0xaf8dd77e18e2fc1d1e7a337bb997462b20788135",
    "0xac98c4f890593b636291122497f4690b6d4489d4",
    "0x77dea4084c9223a309dc722d4b48b35d68ca23cb",
    "0xacc877eee24badc85b0f4320b32f5b1cb927912c",
    "0xb53e9378f3410fd20a9dc3265f09dfd0833ac38e",
    "0xad188c7ad27b29766e9fc78fce306f5aca48c75f",
    "0x66720831aa42f371a415c4c80425e3ddf527f8e3",
    "0xadd03dcec81e69d562b501446f463b5654716ef1",
    "0x39c357f92931fa951c2d8427ca5948a274f6e065",
    "0xae3754df0459fa3c5a65a90155d71e09c9a604fd",
    "0xfc9c99051639edc0bc0ba970d0900a2ff1bce3d9",
    "0xb0b7485716670dac6d9d55d8afdff33e51941679",
    "0xebd8843f53719e12a282ac79222c6038a0c2902f",
    "0xb1b1e28ca8726f521a3731fa23ee7f3a36434d45",
    "0xe318a81a4be1f6ef3d2b7402d5384ac8f31a0d2f",
    "0xb246182896b4aadeb36ce5037c2d5cbe4080cddf",
    "0x71f93e1b8538b75476642c0cd62c51b921040294",
    "0xb2db8b2f615f01a1494912dce7793b851255a30b",
    "0xb85cea3752f030bcd141cd212619a6443b60a916",
    "0xb3421fecb526e5fb1f879329c5fc5d7e6848fb7c",
    "0x1c0014f43751c7cc1de0eea441a05ccd6eec6d8b",
    "0xb34b35cf85f8019183833156aefa04a16fb39262",
    "0xd4e673945c2702ff763cfd76343a4ff8ea0b62db",
    "0xb35cce10163236d7bb1cf481ddd94c310b5cd5eb",
    "0xd48ad0e91f911b1a9f95dbd8b626f10b3683d312",
    "0xb3c6378124e2e0fcb317fa9a02d3635d313c0da7",
    "0xfca164cc5f3dbab7bc6212a5835b38b6cb4ce95a",
    "0xb3f99ed9f9980e86912d38a33e326c7d94c34a96",
    "0x795c98592026e6b53fc14d3082735379cf74741d",
    "0xb78bb785c767ae5ae43fcedb0bf43dbeb02b51d9",
    "0x68ad1fa00cb9d499b73e85c6449766374463b6b2",
    "0xbbd16b83f52f97639f3a14241c8715f9a49a0ae9",
    "0x24877757fd4c9a029e702f12af7dfe3fbd57820e",
    "0xbc52a00343846f684109299ad1994ec5ecc73e5d",
    "0x3297298d7975bcc696c31aeb58fba8a5d03287ea",
    "0xbc6d77aa4fc4407897bac23d78dd4bdc1d5f8676",
    "0x4599a034c1038311daad9bc4e7d217eb8c627367",
    "0xbd1f5708826d391d5a60785517e25887e5770319",
    "0x8dd1270731b48bd907b15554df4de4a33d21a1d4",
    "0xbd89e9615b9d0b4139b967b45969baa6bc7e7ce2",
    "0x48c32686032579bba1751899c453d8cecc50fe63",
    "0xbe6f610f8ec78c41e6ab066dbec611ec1c777da0",
    "0x537b2671238dc3db1352668d0f4f4651da8ecc6d",
    "0xbeda3e276368dc2d2b01684ee6c4b879af54200c",
    "0x6ddf54d654d417c87ae3e056f3709317fe97ebeb",
    "0xbefd0b33bdf3f2c41d5b3eb754253b0495d0d131",
    "0xfc1c139844d1546952de1f95b61a95f419d99116",
    "0xc10e3eb46da0416808b289801f6af8dbf9b21207",
    "0x9ca330a6edcfca9a788ab6f9e110ff0fbdfee0a9",
    "0xc15a6c9b41881c1993d372b1b645fc2c50cbe1c0",
    "0xaa9d12f9be44eb06f10f1837325ed17e44457bff",
    "0xc1876bb98df09206a7929350e40eb0b970b2c05a",
    "0xeb1eee982a0817f48b664ba5a047a3ff853992dc",
    "0xc4f31f351694472a39d235cd2a9b7c8ba5c8991b",
    "0x8dcf76075fdce932c3b1aeb93a9ff9d3fe310274",
    "0xc6adc0fafca307d62fd9fdb92743a168c7a7be43",
    "0x64ffee184881c440e85e39c19897939fed3d1f3f",
    "0xc988ac3aa0ca5a407a78627cbe1f842ecc70f233",
    "0xcce963f18cfa8911564dc6c391239a4d4392eb54",
    "0xcd7298f99188df36d6386225f77477ae825ffa3f",
    "0xce9455036163d95664bfacb82629d843ca57181b",
    "0xd012d97ca4f520334637f2be199b76cdf6f47d48",
    "0xd07f86ff1a08e5a072b178706bf61d51734bb21e",
    "0xd22d0690435e51e019a3fc6cdcf7f98edce12ecf",
    "0xd26f7f6b3bdacf9b34aa3884962ff0d4f151c7b0",
    "0xd425f4d46546a7bebc2bdb2dcebcd97fd040b5b9",
    "0xd42692df64b396256d4b85b9ba7504c35f577abd",
    "0xd49a3b498fae4ca6fc440abbafa3f0833e6b8699",
    "0xd4d21a75656741be8a53e70268cfd1c7243770e7",
    "0xd5fb0df508592c2021b6e4b49e45bd1288516a1e",
    "0xd78cb6a5a634792f4d0aea63086c1e51e8e14fc8",
    "0xd8bf3c2840f6f5130696d1a88f9e3d132f82396b",
    "0xde3b460edcce79e5df7f5c847ba68b04d3e89d39",
    "0xdef9cbc7a7b9fa1228e3fef7be3da4755a602035",
    "0xe010f2f41845301e3f48c8a076d95c00e4d3735f",
    "0x0f97f0886d5054867b98fae943ec366d8690ab50",
    "0xe0d4938f6325f0f4f944a581fc5bb68faa07f47a",
    "0xe2bc44e11c40046308eca443266615bdf1809b2f",
    "0xe39cf76ec23f1a7997876b64666ec6c0326baa2d",
    "0xe3be8df2324a6566fcf77f9c756f20012679fc2c",
    "0xe4678d47aabda02213973ecb11ae715d44a16ef3",
    "0xe4785c77743c0db7694e43fc78ce20fcdbb074e7",
    "0xe4c4b4d613030bd227db0d5b52a44fc0da090b87",
    "0xe5842d1e0ca3390659c6c52b002864c56174b5c2",
    "0xe601011a8cf77d0eba727939396ca2584f7833f8",
    "0xe6177e27f748894ae7c60be7be1b8bf9e009c000",
    "0xe696e0f6345687187ee3ed80e4a4ee1fd2cb3dae",
    "0xe6e81ea611c167a98abaf54b52c0f30d29d1ea5c",
    "0xe6ec607657b2646a59f46e592a5a1bcefa5cfb20",
    "0xe795947aed9d1fa2bbd78e19ff33cd7d626648e4",
    "0xe7985c511d1bf0c7668757650b974513628dea7c",
    "0xe8c9ef2b576fac51853cbc45cc8e523b548f8f7d",
    "0xe8fffefd61b7a061b20debc2c2c6658043447ec9",
    "0xe911b64bd8e2106bb91acd31cf6bc5cb72398486",
    "0xec225a1fd31603790f5125ab5621e80d8047e901",
    "0xf020f464f284dfdc0511d66edea22a1dc042b5c6",
    "0xf1159efd92033193bd14213c015dbdd65c0aee1a",
    "0xf2bd3daea43ed0e66bbdf5ac8b0bc853903b3187",
    "0xf2f77abda9e01c3500197894e92beccad4633c48",
    "0xf417a9fe907b8f47849d2dd59b686bbc0b4d3566",
    "0xf520523d8e902315d2dfb3f450effe7d40e8272e",
    "0xf637096d4cbdd53e4539e9bc8f6a372d73808757",
    "0xf81a48410c448b1d587be9bbb3b23938e9f4bfd4",
    "0xf92bd9e377bd59b920b77a265cca124c8c81483e",
    "0xfc89dcfcd82c343502b8881cbb0596935163cb2a",
    "0xfef6cef16135bffd2040932b2e43c825864838d7",
    "0xff879c97f1497be13aae8b109afeed3e4e1d930f",
    "0xe3413e8f9852045922e33dcd06ff5d60e4d38c4a",
    "0xb3c828f138a60f6e3b960d51ed21d4c0cebbeb8b",
    "0xaa9b76fed9d8c3da13336be988b1ddc160cd91e1",
    "0xfe8cb1c02c91f7d7d12e5d702c16afe6f7c9d41f",
    "0xaf1f777092e5bc9407e47ae1396912aeb52d2842",
    "0x3f06137f03cd76d49eacd008b8be2e9f4ee44151",
    "0x4e720f30e19a90cb354a4376902571ebb2b44553",
    "0xa25a3ec00feaf07df13f4ab3fb2129079f4798fd",
    "0xf6055d0aad611bb76bc730208b79552fa96992d5",
    "0xcddd5320b531cbf874f035f2f49f2997bedd08e0",
    "0x17420234f2525e72156f57c79234a100060746ac",
    "0xb653961e0aa3e4fb19eb9043a728df0b17a030c7",
    "0xf6843599d50f804d1d6bab9b84137b92ca53f327",
    "0xdcb2e8fad27895e4d96abe33af7cfdcb7aac7507",
    "0x5e7794141088d49f95b11e0d96527d639e66392e",
    "0x5104ab7824eaa9b1b349e70044d4adbce0758da4",
    "0xcab6c01601302051c8a10aa05fe97ff2e895bbb3",
    "0xa9ad5ad9ebe5bfa75c6102b07e049af3d7d3397a",
    "0x031947da42998417de14b69dba631801144b2586",
    "0xc1ca25dddaf66bd2480cf669713c12c0c7cb0b34",
    "0xa7d41740063eabe8ca2cdc75ea90c55ea696dc3d",
    "0x9d3c47ed12698048df563935c6726c9f4b013a6a",
    "0x01bb57dde333314a7614973c67c60c598f076e14",
    "0xe8d8b73ccc85ded891ad41893ebbb0d684350e04",
    "0xe7474357094778dd93657211d7cd1c8c6e0c9954",
    "0x3b64c6c077fbb545e72469c5f2e261d6bc16180a",
    "0xb50012fe187f2903bf6e112a4e60d2bd05fc0fcc",
    "0x55b5f463b80bd2c9c1e54fd115c74e69cd7e201b",
    "0x09f5bbf72089ce3cb98f97f55974a37fcf399aeb",
    "0x5c4c503956a75ef0cc77705c36642e2ca1fb2322",
    "0x0a59eedbb9b15d5cbbbbd7db91a4c1d5c09631e8",
    "0xf10dc48a05edf0b4a1e2beec730b828c7298790d",
    "0x9ea11f13dedae6506179892acf1bec0b563dbd51",
    "0xf1702d656e5a6b211ab998a3fa469c352cce0bee",
    "0xa050db47160cd522d8c72066ee9c162cb4d49cac",
    "0xba8dc03bb312a96aa91c5df060d9276ec6f5e100",
    "0x8cb5837dcc57756d8be623d39f49e0fe34442076",
    "0xe24a01365454b9ecb2bd9556d81e6775141f610e",
    "0x067c2105ce91e6c24cd5bc10cc8e0fabb98eae90",
    "0xbbf57a3f1114868895ea01802105863c2f782875",
    "0xbdd837ee9cacaefaa1071c6b2a933468cf9003e3",
    "0x3f748290f1d81f9082c9428532dd676a0846d4ba",
    "0xe19dcc59fdd455cd7920015c861cb8cfccd53c3f",
    "0x903339eb069a49bbe67635b4a3451711061e065d",
    "0x196fa212b891f069aedfcd62e325db2f78288d56",
    "0xdc638b317a87ecc06d68d6539187bcb99ce1c45d",
    "0xec6f9cc2afdc2a90fe9e5e53211550148ff5599e",
    "0x3b5f87c863557fd705916e6531c951c3fab456bd",
    "0xec256a91daa9932038535cdcc3715cf85bc30ef6",
    "0xd6a2133bc96731e4ad8c42c12135aab2dcde1095",
    "0xe5da840cf8b4c203d4979021ec500e2688244cbe",
    "0x76a89dbd709835b9d1a3d60ee31f9e6c54cc8ac6",
    "0x2b4535c555dd38a9b3d5fd6abd45e6043af7f2bf",
    "0xa9967463e5cb83dfba962f1727053b18ee14e8d5",
    "0x97207d4657ea17d7cdf20bec80c8a0c4100a2bdb",
    "0x194cc57b57c8eca514fdf58bac8cfad5e20e133d",
    "0xc629e268c75c9f248a5448907066fe95092e6505",
    "0x7715bb929e84727cd736be06c8819fcb718311d9",
    "0x097ec857ae9b4d16591326c76185780e10c47475",
    "0xa71885755301ac2c99e92c482e3156780c75b3d2",
    "0x8a210a9ac656910c4897fd8005bcd2286aae1c35",
    "0x1b2d3f8de833ea8f573264742d1508b5907c6a5f",
    "0x75d639109f0c1c9afc231a29291d54b619e5fb34",
    "0x49f63ec5ace937798724aa71e0caa42827952215",
    "0xd729571aaaeb486931f4d3f3dcd97d219ec1c9d3",
    "0x7007e2a4301bfa3f6799506f1c5150e15e425fbb",
    "0x9553426e8750184cb355d8f19b7fcfc214ac1c75",
    "0x934b231e51eb0fc4f07b475e16e218b9687e0be7",
    "0x83082acd5ab84aa97b058aca08d3f475fa2448dd",
    "0x16649858623119cc3615fb8f52162ac9f1b7de09",
    "0xe828df9e7aae479e8c9eb23ca643a07c7e5d2637"]


  const leaves = addresses.map(x => keccak256(x))
  const tree = new MerkleTree(leaves, keccak256, { sortPairs: true })
  const buf2hex = x => '0x' + x.toString('hex')

  console.log("La merkle root è", buf2hex(tree.getRoot()))

  const leaf = keccak256(window.ethereum.selectedAddress) // address from wallet using walletconnect/metamask
  const proof = tree.getProof(leaf).map(x => buf2hex(x.data))


  //End Merkle generator

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "0xf4Ac2D6Db4c882c8ba70ffA25D607B0922831C7A",
    SCAN_LINK: "https://etherscan.io/address/0xf4Ac2D6Db4c882c8ba70ffA25D607B0922831C7A",
    NETWORK: {
      NAME: "",
      SYMBOL: "ETH",
      ID: 5,
    },
    NFT_NAME: "GOK",
    SYMBOL: "Ghosts of Koganei",
    MAX_SUPPLY: 333,
    WEI_COST: 8800000000000000,
    DISPLAY_COST: 0.0088,
    GAS_LIMIT: 300000,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });



  async function Mint() {
    const WL = await blockchain.smartContract.methods.onlyWhitelisted().call()
    let paused = blockchain.smartContract.methods.paused().call()
    console.log(WL);
    // const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let merkleProof = proof;
    console.log("La merkle proof del wallet corrente è :", merkleProof);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    if (WL != false && paused != false) {
      if (addresses.includes(window.ethereum.selectedAddress.toLowerCase())) {
        alert("You are in Whitelist ✅")
        blockchain.smartContract.methods
          .mintWl(mintAmount, merkleProof)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFeedback(
              `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
      } else {
        alert("You are not in Whitelist ❌")
      }

    } else if (WL == false && paused != false) {

      blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }
    else {
      alert("You are not whitelisted ❌ ")
    }
    // };




  }


  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };




  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImg alt={"example"} src={"/config/images/example.gif"} /> */}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              //border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.NFT_NAME} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {/* Excluding gas fees. */}
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          Mint();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            /> */}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            {/* Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action. */}
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            {/* We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit. */}
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
